import "./App.scss";
import Layout from "./Layout/Layout";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PolitikaKonfidentsialnosti from "./PolitikaKonfidentsialnosti/PolitikaKonfidentsialnosti";

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path={'/'} element={<Layout />} />
            <Route path={'/politika-konfidentsialnosti'} element={<PolitikaKonfidentsialnosti />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
