import Footer from "../Footer/Footer";
import {Header} from "../HeaderAndIntro/Header/Header";
import "./PolitikaKonfidentsialnosti.scss";

export default function PolitikaKonfidentsialnosti() {

    return (
        <div className="Layout">
            <div className="HeaderAndIntro">
                <Header />
            </div>

            <div className="PolitikaKonfidentsialnosti content">

                <h1 className="title">Политика конфиденциальности</h1>

                <p>Дата последнего обновления: 14 февраля 2025 года</p>
                <p>На сайте проекта “Хочу найти” hochunaiti.com (далее “мы”, “сайт”, “Хочу найти”), мы уважаем вашу конфиденциальность и обязуемся защищать ваши персональные данные. Настоящая Политика конфиденциальности объясняет, как мы собираем и используем вашу информацию при посещении нашего сайта.</p>
                <p><b>1. Собираемая информация</b></p>
                <p>Сайт “Хочу найти” не предполагает регистрации и подписок на рассылки. Соответственно, “Хочу найти” не получает и не сохраняем ваши персональные данные, такие как имя, телефон или адрес электронной почты.</p>
                <p>Сайт “Хочу найти” собирает неперсональную информацию пользователей, такую как тип браузера, информация об устройстве и действия на сайте. Эта информация используется в аналитике и помогает нам улучшать функциональность сайта и предоставлять пользователям полезную информацию.</p>
                <p><b>2. Как мы используем вашу информацию</b></p>
                <p>Мы используем собранную информацию для таких целей:</p>
                <ul>
                    <li>Анализ и улучшение работы нашего сайта.</li>
                    <li>Предотвращение мошенничества и обеспечение безопасности нашего сайта и пользователей.</li>
                </ul>
                <p><b>3. Использование cookies</b></p>
                <p>Сайт “Хочу найти” использует cookies для улучшения вашего опыта работы на сайте. Cookies — это небольшие файлы, которые сохраняются на вашем устройстве и позволяют нам собирать информацию о том, как вы используете наш сайт. Вы можете управлять настройками cookies через свой браузер.</p>
                <p><b>4. Раскрытие и передача данных</b></p>
                <p>Мы не продаем, не арендуем и не передаем неперсональную информацию, собранную на сайте третьим лицам. Однако на сайте “Хочу найти” могут использоваться проверенные и надежные дополнительные программы и сервисы по улучшению работы и пользования сайтом, которые также используют Cookies.</p>
                <p><b>5. Безопасность данных</b></p>
                <p>Мы принимаем разумные меры для защиты ваших персональных данных от несанкционированного доступа, изменения или уничтожения. Однако следует помнить, что ни один метод передачи данных через интернет или электронного хранения не является полностью безопасным.</p>
                <p><b>6. Ваши права</b></p>
                <p>Вы имеете право:</p>
                <ul>
                    <li>Отказаться от использования сайта “Хочу найти”</li>
                    <li>Отключить Cookies в настройках вашего браузера и не передавать неперсональную информацию на сайт “Хочу найти”</li>
                    <li>Использовать другие инструменты анонимизации вашего пользования сайтом.</li>
                </ul>
                <p>Однако, в таком случае, мы не можем гарантировать удобную и корректную работу сайта “Хочу найти”.</p>
                <p><b>7. Изменения в Политике конфиденциальности</b></p>
                <p>Мы можем время от времени обновлять эту Политику конфиденциальности. При внесении изменений мы опубликуем обновленную политику на этой странице с указанием новой даты последнего обновления. Пожалуйста, регулярно проверяйте эту страницу на наличие изменений.</p>
                <p><b>8. Контактная информация</b></p>
                <p>Если у вас есть вопросы или замечания по поводу данной Политики конфиденциальности или если вы хотите воспользоваться своими правами относительно ваших данных, которые собраны во-время вашего использования сайта “Хочу найти”, вы можете связаться указанным ниже способом:</p>
                <p>Электронная почта <a href={'mailto:info@hochunaiti.com'}>info@hochunaiti.com</a></p>
            </div>

            <Footer />
        </div>
    )
}
